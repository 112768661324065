@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.lat-menu {
  --width: 100%;
  --background: rgba(209, 243, 64, 0.8);
}

ion-toolbar {
  --color: white;
  --background: transparent;

  &.bg-primary-default {
    --ion-background-color: --ion-color-primary;
  }
  &.bg-secondary-default {
    --ion-background-color: --ion-color-secondary;
  }
  &.bg-tertiary-default {
    --ion-background-color: --ion-color-tertiary;
  }
}

ion-content {
  --ion-background-color: transparent;

  &.bg-primary-default {
    --ion-background-color: --ion-color-primary;
  }
  &.bg-secondary-default {
    --ion-background-color: --ion-color-secondary;
  }
  &.bg-tertiary-default {
    --ion-background-color: --ion-color-tertiary;
  }
}

ion-header::after {
  background-image: none;
}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 12, 2021 */
@font-face {
  font-family: "bebas";
  src: url("./assets/fonts/bebasneuebold-7b9le-webfont.woff2") format("woff2"),
    url("./assets/fonts/bebasneuebold-7b9le-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "crafterrough";
  src: url("./assets/fonts/crafter-rough-webfont.woff2") format("woff2"),
    url("./assets/fonts/crafter-rough-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "raleway";
  src: url("./assets/fonts/raleway-bold-webfont.woff2") format("woff2"),
    url("./assets/fonts/raleway-bold-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "raleway";
  src: url("./assets/fonts/raleway-italic-webfont.woff2") format("woff2"),
    url("./assets/fonts/raleway-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "raleway";
  src: url("./assets/fonts/raleway-medium-webfont.woff2") format("woff2"),
    url("./assets/fonts/raleway-medium-webfont.woff") format("woff");
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: "raleway";
  src: url("./assets/fonts/raleway-regular-webfont.woff2") format("woff2"),
    url("./assets/fonts/raleway-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "avenir";
  src: url("./assets/fonts/Avenir-Medium.woff2") format("woff2"),
    url("./assets/fonts/Avenir-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "avenir";
  src: url("./assets/fonts/Avenir-Light.woff2") format("woff2"),
    url("./assets/fonts/Avenir-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "avenir";
  src: url("./assets/fonts/Avenir-Black.woff2") format("woff2"),
    url("./assets/fonts/Avenir-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "avenir";
  src: url("./assets/fonts/Avenir-Book.woff2") format("woff2"),
    url("./assets/fonts/Avenir-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "fira_sans";
  src: url("./assets/fonts/firasans-regular-webfont.woff2") format("woff2"),
    url("./assets/fonts/firasans-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "fira_sans";
  src: url("./assets/fonts/firasans-light-webfont.woff2") format("woff2"),
    url("./assets/fonts/firasans-light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "fira_sans";
  src: url("./assets/fonts/firasans-bold-webfont.woff2") format("woff2"),
    url("./assets/fonts/firasans-bold-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "fira_sans";
  src: url("./assets/fonts/firasans-extrabold-webfont.woff2") format("woff2"),
    url("./assets/fonts/firasans-extrabold-webfont.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}
